<script setup lang="ts">
const { isImpersonating, session } = useAuth()
</script>
<template>
  <div class="min-h-svh w-full bg-slate-100">
    <div class="flex flex-col items-center justify-center gap-4">
      <nav class="relative z-10 w-full bg-white shadow-md">
        <div
          class="mx-auto flex flex-row items-center justify-between px-4 py-2 sm:px-6 xl:px-8 xl:py-2.5"
        >
          <NuxtLink
            class="relative mr-8 flex shrink-0 flex-row items-center justify-start xl:mr-0 xl:w-56"
            to="/dashboard"
          >
            <LogoSvg class="h-8" />
          </NuxtLink>
          <div class="hidden grow items-center md:flex xl:justify-center">
            <OnboardingProgressIndicator />
          </div>
          <div class="flex w-56 items-center gap-4">
            <LocaleSwitcher :locales="['da_DK', 'en_US']" />
            <FormButton to="/dashboard" variant="secondary">
              {{ $t("onboarding.exit") }}
              <SvgoPikaClose class="ml-2 size-5" />
            </FormButton>
          </div>
        </div>
      </nav>
      <div class="flex w-full max-w-4xl flex-col p-6 sm:p-10">
        <slot />
      </div>
    </div>
    <NotificationToast />
    <ImpersonatingBadge
      v-if="session && isImpersonating"
      :email="session.user.email"
    />
    <DeveloperToolbox />
  </div>
</template>
<style>
@reference "tailwindcss";
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from {
  opacity: 0;
  @apply translate-x-10;
}
.page-leave-to {
  opacity: 0;
  @apply -translate-x-10;
}
</style>
